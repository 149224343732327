export class EventEmitter {
  constructor() {
    this.callbacks = {};
  }
  on(event, cb) {
    if (!this.callbacks[event]) this.callbacks[event] = [];
    this.callbacks[event].push(cb);
  }
  emit(event, key, data) {
    let cbs = this.callbacks[event];
    if (cbs) {
      cbs.forEach((cb) => cb(key, data));
    }
  }
  off(event, cb) {
    if (!this.callbacks[event]) return;
    this.callbacks[event] = this.callbacks[event].filter(
      (callback) => callback !== cb
    );
  }
}

export const keepOldNavigation = (id) => {
  return id !== "reports";
};

export const Origo = {
  BASE_URL: (
    (document.querySelector('script[src*="/integration/origo.js"]') || {})
      .src || ""
  ).replace(/\/integration.*$/, ""),

  AVAILABLE_LANGUAGES: ["fi", "sv", "en"],

  urlTo: (path) => {
    return `${Origo.BASE_URL}/${path}`.replace(/([^:])\/\/+/g, "$1/");
  },

  cache: {
    set: (key, item) => {
      Origo.cache._storage[key] = item;
      Origo.cache.event.emit("cache-set", key, item);
    },

    get: (key) => {
      return Origo.cache._storage[key];
    },

    clear: () => {
      Origo.cache._storage = {};
    },

    _storage: {},

    event: new EventEmitter(),
  },

  Widget: {
    widgets: [],

    Mode: {
      SETTINGS: "settings",
      VIEW: "view",
    },

    Size: {
      DEFAULT: "default",
      EXPANDED: "expanded",
    },

    register: (widgetId, baseUrl, widgetRenderer) => {
      if (widgetRenderer) {
        Origo.widgets = Origo.widgets || {};
        Origo.widgets[widgetId] = {
          baseUrl: baseUrl,
          render: widgetRenderer,
        };
      }
    },

    setSize: (widgetId, size) => {
      let url = window.location.pathname;

      if (size == Origo.Widget.Size.EXPANDED) {
        url = url + `#expand-${widgetId}`;
      }
      window.history.pushState("", document.title, url);
      window.dispatchEvent(new HashChangeEvent("hashchange"));
    },
  },
};

window.Origo = Origo;
